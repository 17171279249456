import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import background from '../../assets/img/new-12.png';
import SectionContainer from '../../components/shared/SectionContainer';
import * as Yup from 'yup';
import * as paremkStyles from "./paremk.module.css"
import ParamosDezute from './ParamosDezute';
import ParamosDialogas from './ParamosDialogas';


const query = graphql`
  {
	strapiSavanoriaiVaikams {
		paremti {
		  kodel_remti {
			Antraste
			Nuotrauka {
			  localFile {
				publicURL
			  }
			}
		  }
		  logo {
			localFile {
			  publicURL
			}
		  }
		}
	  }
  }
`

function Paremk() {
	const isBrowser = typeof window !== 'undefined'
	const data = useStaticQuery(query)?.strapiSavanoriaiVaikams?.paremti;
	const [deviceSize, changeDeviceSize] = useState(isBrowser ? window?.innerWidth : '');

	const [open, setOpen] = useState<boolean>(false);
	const [sum, setSum] = useState<number>(50);
	const [initialType, setInitialType] = useState<'vienas' | 'menesis'>('menesis');

	useEffect(() => {
		const resizeW = () => changeDeviceSize(isBrowser ? window?.innerWidth : '');

		window?.addEventListener("resize", resizeW); // Update the width on resize

		return () => window?.removeEventListener("resize", resizeW);
	});

	return <>
		<div>
			<SectionContainer style={{ padding: '32px', minHeight: '700px', display: 'flex', flexWrap: 'wrap', gap: '36px' }} src={background}>
				<div style={{ background: 'white', borderRadius: '8px' }}>
					<ParamosDezute sums={[50, 25, 10]} setOpen={setOpen} handleSum={setSum} handleType={setInitialType} type={initialType}></ParamosDezute>
				</div>

				<div className={paremkStyles.tekstas}>Kad nė vienas neliktų vienišas!</div>
			</SectionContainer>

			<Headeris style={{ margin: '4rem 0' }}>Kam reikalinga parama?</Headeris>
			<SectionContainer style={{ display: 'flex,', flexDirection: 'column' }}>
				{data?.kodel_remti?.map((item: any, index: number) => <div key={index} style={{ display: 'flex', flexWrap: 'wrap', gap: '50px', marginBottom: '32px', justifyContent: 'center' }}>
					<img style={{ maxWidth: '500px', width: '100%', order: index % 2 === 0 ? '1' : '2' }} src={item.Nuotrauka.localFile.publicURL} />
					<NuotraukosTekstas style={{ flex: '0 1 500px', order: index % 2 === 0 ? '2' : '1', textAlign: 'justify' }}>{item.Antraste}</NuotraukosTekstas>
				</div>)}
			</SectionContainer>

			<SectionContainer>
				<Headeris style={{ margin: '4rem 0' }}>Ačiū mūsų paramos surinkimo partneriams</Headeris>
			</SectionContainer>

			<SectionContainer style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', marginBottom: '4rem', gap: '2rem' }}>
				{data?.logo?.map((item: any, index: number) => <img key={index} style={{ width: '130px' }} src={item.localFile.publicURL} />)}
			</SectionContainer>
		</div>

		<ParamosDialogas open={open} setOpen={setOpen} sum={sum} type={initialType}/>
	</>

}

export default Paremk;

const Headeris = styled.h2`
	font-weight: 600;
	font-size: 48px;
	line-height: 140%;
	color: #0E558F;
`

const NuotraukosTekstas = styled.div`
	display: flex; 
	justify-content: center;
	align-items: center;

	font-weight: 500;
    font-size: 24px;
    line-height: 45px;
    color: #0E558F;

	@media (max-width: 1050px) {
		order: 2 !important;
		font-size: 18px !important;
	}
`

const Button = styled.button`
	background: #0A2540;
	border-radius: 12px;
	width: 100%;
	padding: 12px 48px;
	color: white;
`

const SignupSchema = Yup.object().shape({
	firstName: Yup.string().required('Prašome įvesti savo vardą'),
	lastName: Yup.string().required('Prašome įvesti savo pavardę'),
	email: Yup.string().email('Neteisingas elektroninis paštas').required('Prašome įvesti elektroninį paštą'),
	phone: Yup.string().matches(/(86|\+3706)\d{7}/, 'Neteisingas telefono numeris').required('Įveskite savo telefono numerį'),
	agreePolicy: Yup.bool().oneOf([true], 'Privalote sutikti su privatumo politika')
});

